import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, InputBox } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';


const SearchBox = ({ searchTerm, setSearchTerm, textHolder }) => {

    const [txtSearch, setTxtSearch] = useState(searchTerm.txtSearch || '');

    function handleSearch(e) {
        e.preventDefault();
        setSearchTerm({
            ...searchTerm,
            txtSearch: txtSearch.trim()
        });
    }

    return (
        <Wrapper>
            <InputBox onSubmit={handleSearch}>
                <input type='text'
                    placeholder={textHolder}
                    value={txtSearch}
                    onChange={(e) => { setTxtSearch(e.target.value); }}
                />
                <button type='submit'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
            </InputBox>
        </Wrapper>
    )
}

SearchBox.propTypes = {
    setSearchTerm: PropTypes.func.isRequired,
    textHolder: PropTypes.string,
}

SearchBox.defaultProps = {
    textHolder: 'Search...',
}

export default SearchBox;
