import styled from 'styled-components';

export const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    flex-grow: 1;

    .btn-add{
        position: fixed;
        right: 20px;
        bottom: 30px;
        font-size: 2.5rem;
        color: var(--morienGreen);
        @media screen and (max-width: 768px){
            bottom: 60px;
        }
    }
  
`;

export const Content = styled.div`
    flex-grow:1;
    overflow-y:scroll;
    @media screen and (max-width: 768px){
        padding-bottom: 50px;
    }
`;