import axiosClient from "./axiosClient";

const projectApi = {
    searchProject(params) {
        const url = '/project';
        return axiosClient.get(url, { params: params });
    },
    getProjectDetails(params) {
        const url = '/project/details';
        return axiosClient.get(url, { params: params });
    },
    addNewProject(params) {
        const url = '/project/add_new';
        return axiosClient.post(url, params);
    },
    searchProjectClient(params) {
        const url = '/project_client';
        return axiosClient.get(url, { params: params });
    },
}

export default projectApi;
