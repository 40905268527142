import React, { useState } from "react";

import ImgLogo from '../../assets/images/logo.png'
import { useLogin } from "./useLogin";
import { Wrapper, Content, Logo } from './styles'
import Loading from "../Loading";

const Login = () => {
    const { setLoginInfo, isLoading } = useLogin();
    const [userID, setUserID] = useState('');
    const [uPassword, setUPassword] = useState('');
    function handleOnSubmit() {
        if (!userID) {
            alert('ユーザーIDに入力してください。');
            return;
        }
        if (!uPassword) {
            alert('パスワードに入力してください。');
            return;
        }
        const loginInfo = {
            userID: userID,
            password: uPassword
        };
        setLoginInfo(loginInfo);
    }

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <Content>
                <Logo src={ImgLogo} alt='Logo' />
                <input type='text'
                    placeholder='ユーザーID'
                    value={userID}
                    onChange={(e) => setUserID(e.target.value)}
                />
                <input type='password'
                    placeholder='パスワード'
                    autoComplete='on'
                    value={uPassword}
                    onChange={(e) => setUPassword(e.target.value)}
                />
                <button type='submit' onClick={handleOnSubmit} >ログイン</button>
            </Content>
        </Wrapper>
    );
};

export default Login;
