import React from 'react';
import { MainTitle, SubTitle, Wrapper } from './styles';
import PropTypes from 'prop-types';

const HeaderDetails = ({ mTitle, subTitle, alignTitle }) => {
    return (
        <Wrapper alignTitle={alignTitle}>
            <MainTitle>{mTitle}</MainTitle>
            {subTitle === '' ? (<></>) : (<SubTitle>{subTitle}</SubTitle>)}
        </Wrapper>
    )
}

HeaderDetails.propTypes = {
    mTitle: PropTypes.string,
    subTitle: PropTypes.string,
    alignTitle: PropTypes.string,
}

HeaderDetails.defaultProps = {
    mTitle: '',
    subTitle: '',
    alignTitle: 'flex-start'
}

export default HeaderDetails
