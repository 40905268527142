import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import personaApi from "../../../api/personaApi";


export const usePersonaComDetails = () => {
    const { persona_id } = useParams();

    const [objPersonaCom, setObjPersonaCom] = useState(null);
    const [clientActionList, setClientActionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        const getPersonaComDetails = async () => {
            try {
                setIsLoading(true);
                if (!persona_id) {
                    setObjPersonaCom(null);
                    return;
                };
                const params = {
                    personaID: persona_id,
                }
                const dtResponse = await personaApi.getPersonaComDetails(params);
                const personaDetails = dtResponse?.data?.personaDetails;
                const dtClientAction = dtResponse?.data?.dtClientAction;
                if (personaDetails) {
                    setObjPersonaCom(personaDetails);
                    setClientActionList(dtClientAction);
                } else {
                    setObjPersonaCom(null);
                    setClientActionList([]);
                }
            } catch (error) {
                alert(error);
            } finally {
                setIsLoading(false);
            }
        }
        getPersonaComDetails();
        return;
    }, [persona_id])

    return { objPersonaCom, clientActionList, isLoading }
}