import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, MLabel } from './styles';
import { Link } from 'react-router-dom';

const ClientActionItem = ({ parrentUrl, objClientAction }) => {
    return (
        <Wrapper>
            <Link to={`/${parrentUrl}/client_action/${objClientAction.ClientActionID}`}>
                <span className='title'>{objClientAction.ActionTitle}</span>
                <span ><MLabel>お客様：</MLabel>{objClientAction.ClientName || '－'}</span>
                <div>
                    <span ><MLabel>日付：</MLabel>{objClientAction.ActionDate ? objClientAction.ActionDate.slice(0, 10) : '－'}</span>
                    <span className='ml-30'><MLabel>区分：</MLabel>{objClientAction.ClientActionType || '－'}</span>
                </div>
                <div>
                    <span ><MLabel>代理：</MLabel>{objClientAction.AltStaffName || '－'}</span>
                    <span className='ml-30'><MLabel>担当者：</MLabel>{objClientAction.StaffName || '－'}</span>
                </div>
                <span ><MLabel>内容：</MLabel>{objClientAction.ActionMemo || '－'}</span>
                {/* <div>
                    <span ><MLabel>区分：</MLabel>{objInquiry.InquiryTypeName || '－'}</span>
                    <span className='ml-30'><MLabel>日時：</MLabel>{objInquiry.InquiryDate ? objInquiry.InquiryDate.slice(0, 10) : '－'}</span>
                </div>
                <span ><MLabel>スタッフ：</MLabel>{objInquiry.StaffName || '－'}</span>
                <span ><MLabel>ステータス：</MLabel>{objInquiry.StatusName || '－'}</span> */}
            </Link>
        </Wrapper>
    )
}

ClientActionItem.propTypes = {
    objClientAction: PropTypes.object.isRequired,
}

export default ClientActionItem;
