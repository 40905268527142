import React, { Fragment } from "react";
import SearchBox from "../SearchBox";
import {
  ModalBlock,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from "./styles";

const Modal = ({ title, footer, children, active, hideModal, searchTerm, setSearchTerm, textHolder }) => {
  return (
    <Fragment>
      {active && (
        <ModalBlock>
          <ModalOverlay onClick={() => hideModal()}></ModalOverlay>
          <ModalContainer>
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
              <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} textHolder={textHolder} />
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>{footer}</ModalFooter>
          </ModalContainer>
        </ModalBlock>
      )}
    </Fragment>
  );
};
export default Modal;
