import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const Content = styled.div`
    /* width:100%; */
    flex-grow:1;
    display:flex;
    flex-direction: column;
    overflow-y: scroll;
    font-size: var(--fontSmall);
    padding-bottom: 30px;
        
`;

export const Info = styled.div`
    margin-left: 4px;
    margin-right: 4px;
    .div_btn {
        display: inline-block;
        padding:2px 10px;
        margin: 8px 10px;
        border-radius: 3px;
        background-color: var(--morienGreen);
        border: 1px solid var(--morienGreen);
        color: var(--white);
        text-decoration: none;
    }   
    p {
        margin:0 2px 0 10px;
    }
   
`;

export const MLabel = styled.label`
    padding: 0 2px;
    color: #6c757d;
`