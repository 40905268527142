import { useEffect, useState } from "react";
import projectApi from "../../../api/projectApi";
import Common from "../../../Common";

const dNow = new Date();
const staff = Common.localStorageGetStaff();
const initProjectInfo = {
    clientID: 1,    //必須   
    clientName: "",
    projectName: "",
    projectKana: "",
    dateFrom: `${dNow.getFullYear()}-${(dNow.getMonth() + 1).toString().padStart(2, '0')}-${dNow.getDate().toString().padStart(2, '0')}`,
    dateUntil: `${dNow.getFullYear()}-${(dNow.getMonth() + 1).toString().padStart(2, '0')}-${dNow.getDate().toString().padStart(2, '0')}`,
    staffID: staff.staffID || '',    //固定　自社担当 
    staffName: staff.staffName || '',
}

export const useProjectAddNew = () => {
    const [projectInfo, setProjectInfo] = useState({ ...initProjectInfo });
    const [isSave, setIsSave] = useState(false);
    const [isLoading, setIsLoading] = useState(false);




    useEffect(() => {
        const saveData = async () => {
            try {
                setIsLoading(true);
                const params = {
                    projectName: projectInfo.projectName,
                    projectKana: projectInfo.projectKana,
                    staffID: projectInfo.staffID,
                    staffName: projectInfo.staffName,
                    clientID: projectInfo.clientID,
                    dateFrom: projectInfo.dateFrom,
                    dateUntil: projectInfo.dateUntil,
                }
                console.log(params);
                const dtResponse = await projectApi.addNewProject(params);
                const projectID = dtResponse?.data?.projectID;
                window.location.replace(Common.URL_HOME + '/' + projectID);
                if (projectID) {
                    alert(dtResponse.message);
                }

            } catch (error) {
                alert(error);
            } finally {
                setIsLoading(false);
                setIsSave(false);
            }
        }

        if (isSave) {
            saveData();
        }
    }, [isSave, projectInfo]);

    return { projectInfo, setProjectInfo, setIsSave, isLoading };
}