import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Wrapper, MorienLogo, Menu } from './styles';

import ImgLogo from '../../assets/images/logo.png';

const NavMenu = () => {
    const location = useLocation();
    const isPageLogin = location.pathname.includes('/login');
    if (isPageLogin) return (<></>);
    return (
        <Wrapper>
            < MorienLogo >
                <img src={ImgLogo} alt='Morien-Logo' />
            </MorienLogo >
            <Menu>
                <ul>
                    <li><NavLink to='/project'>物件</NavLink></li>
                    <li><NavLink to='/com'>法人</NavLink></li>
                </ul>
            </Menu>
        </Wrapper >
    )
}

export default NavMenu;
