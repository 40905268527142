import { useState, useEffect } from "react";
import clientApi from "../../../api/clientApi";


export const useClientSearch = () => {
    const [isLoadingClient, setIsLoadingClient] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeModal, setActiveModal] = useState(false);



    useEffect(() => {
        const searchClient = async () => {
            try {
                setIsLoadingClient(true);
                // setActiveModal(false);
                const params = {
                    txtSearch: searchTerm.txtSearch
                }
                const dtResClient = await clientApi.getAllClient(params);
                const dtClient = dtResClient?.data?.dtClient || [];
                if (dtClient) setClientList(dtClient);

            } catch (error) {
                alert(error);
            } finally {
                setIsLoadingClient(false);
                // setActiveModal(true);
            }
        }

        if (activeModal) {
            searchClient();
        };

    }, [searchTerm, activeModal]);

    return { clientList, isLoadingClient, searchTerm, setSearchTerm, activeModal, setActiveModal }
}