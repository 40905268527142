import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, MLabel } from './styles';
import { Link } from 'react-router-dom';

const ProjectItem = ({ objProject }) => {
    return (
        <Wrapper>
            <Link to={`/project/${objProject.ProjectID}`}>
                <span className='title'>{objProject.ProjectName}</span>
                <span ><MLabel>住所：</MLabel>〒{objProject.PostalCode || '―'}　{(objProject.Pref + objProject.City + objProject.AdrNumber + objProject.Building) || '―'}</span>
                <div>
                    <span ><MLabel>登録：</MLabel>{objProject.AddedDate ? objProject.AddedDate.slice(0, 10) : '－'}</span>
                    <span className='ml-30'><MLabel>状況：</MLabel>{objProject.StatusName || '－'}</span>
                </div>
                <span ><MLabel>担当者：</MLabel>{objProject.StaffName || '－'}</span>
                <span ><MLabel>設計：</MLabel>{objProject.DesignName || '－'}</span>
                <span ><MLabel>施工：</MLabel>{objProject.ConstructionName || '－'}</span>
            </Link>
        </Wrapper>
    )
}

ProjectItem.propTypes = {
    objProject: PropTypes.object.isRequired,
}

export default ProjectItem;
