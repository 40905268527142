import React, { useEffect } from "react";
import { useParams } from "react-router";
import Loading from "../../Loading";
import HeaderDetails from '../../../components/HeaderDetails'
import { Wrapper, Content, Info, MLabel } from './styles'
import { useProjectDetails } from "./useProjectDetails";
import ClientActionList from "../../../components/ClientAction/List";

const ProjectDetails = () => {
    const { project_id } = useParams();
    const { setProjectID, objProject, clientActionList, isLoading } = useProjectDetails();
    useEffect(() => {
        setProjectID(project_id);
        return;
    }, [project_id, setProjectID]);

    return (
        <>
            <Wrapper>
                <Loading isLoading={isLoading} />
                {!objProject ? null : <>
                    <HeaderDetails mTitle={objProject.ProjectName} />
                    <Content>
                        <Info>
                            <div>
                                <MLabel>お客様：</MLabel>
                                {objProject.ClientName || '―'}
                            </div>
                            <div>
                                <MLabel>種別：</MLabel>
                                {objProject.ProjectType || '―'}
                            </div>
                            <div>
                                <MLabel>担当者：</MLabel>
                                {objProject.StaffName || '―'}
                            </div>
                            <div>
                                <MLabel>状況：</MLabel>
                                {objProject.StatusName || '―'}
                                {/* {objProject.StatusMemo} */}
                            </div>
                            <div>
                                <MLabel>見込：</MLabel>
                                {objProject.Prospect || '―'}
                            </div>
                            <div>
                                <MLabel>工期：</MLabel>
                                {objProject.DateUntil || '―'} ~ {objProject.DateFrom || '―'} 「{objProject.TermMemo || '―'}」
                            </div>
                            <div>
                                <MLabel>設計：</MLabel>
                                {objProject.PersonaNameofDesign || '―'}
                            </div>
                            <div>
                                <MLabel>施工：</MLabel>
                                {objProject.PersonaNameofConstruction || '―'}
                            </div>
                            <div>
                                <MLabel>TEL：</MLabel>
                                {objProject.TEL || '―'}
                            </div>
                            <div>
                                <MLabel>住所：</MLabel>
                                〒{objProject.PostalCode || '―'}　{(objProject.Pref + objProject.City + objProject.AdrNumber + objProject.Building) || '―'}
                            </div>
                            <div>
                                <MLabel>面積：</MLabel>
                                {objProject.AreaSquareMeter || '―'}㎡　{objProject.AreaMemo || ''}
                            </div>
                            <div>
                                <MLabel>物件メモ：</MLabel>
                                {objProject.ProjectMemo || '―'}
                            </div>
                            {objProject.urlFolder &&
                                <div>
                                    <MLabel>関連ファイル:</MLabel>
                                    <a target="_blank" rel="noreferrer" href={objProject.urlFolder} className="div_btn" >開く</a>
                                </div>
                            }
                            <ClientActionList parrentUrl='project' projectID={objProject.ProjectID} projectName={objProject.ProjectName} clientID={objProject.ClientID} clientName={objProject.ClientName} clientActionList={clientActionList} />
                        </Info>
                    </Content>
                </>
                }
            </Wrapper>
        </>
    );
};

export default ProjectDetails;
