import Common from "./Common"

const Auth = {
    isLogin: () => {
        const { aUserID, aToken, aStaffID, aStaffName } = Common.localStorageGetToken();
        if (!aUserID || !aToken || !aStaffID || !aStaffName) return false;
        return true;
    }
}

export default Auth
