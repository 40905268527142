import React from 'react';
import PropTypes from 'prop-types';
import { Header, DataList, Wrapper } from './styles';
import ClientActionItem from '../Item';
import { Link } from 'react-router-dom';


const ClientActionList = ({ parrentUrl, projectID, projectName, clientID, clientName, clientActionList, persinaID }) => {
    return (
        <Wrapper>
            <Header>
                <div>問合せ</div>
                <Link to={{
                    pathname: `/${parrentUrl}/client_action/add_new`,
                    state: {
                        projectID: projectID,
                        projectName: projectName,
                        clientID: clientID,
                        clientName: clientName,
                        persinaID: persinaID
                    }
                }} className='btn'>登録</Link>

            </Header>
            {clientActionList.length < 1 ? <h5 style={{ textAlign: 'center' }}>データなし</h5> :
                <DataList>
                    {clientActionList.map((objClientAction) => {
                        return (<ClientActionItem key={objClientAction.ClientActionID} objClientAction={objClientAction} parrentUrl={parrentUrl} />)
                    })}
                </DataList>
            }
        </Wrapper>
    )
}

ClientActionList.propTypes = {
    projectID: PropTypes.string.isRequired,
    projectName: PropTypes.string,
    clientID: PropTypes.string.isRequired,
    clientName: PropTypes.string,
    clientActionList: PropTypes.array,
    parrentUrl: PropTypes.string.isRequired,
    persinaID: PropTypes.string,
}

ClientActionList.defaultProps = {
    projectName: '未入力',
    clientName: '未入力',
    clientActionList: [],
    persinaID: '',
}
export default ClientActionList;
