import axiosClient from "./axiosClient";

const clientApi = {

    getAllClient(params) {
        const url = '/client/all';
        return axiosClient.get(url, { params: params });
    },
}

export default clientApi;
