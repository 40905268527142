import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import personaApi from "../../api/personaApi";



export const usePersonaCom = () => {
    const location = useLocation();
    const nameSearch = useMemo(() => {
        return (new URLSearchParams(location.search)).get("search") || '';
    }, [location.search]);

    const initSearchTerm = {
        mode: 'persona_com',
        txtSearch: nameSearch
    }

    const [searchTerm, setSearchTerm] = useState(initSearchTerm);
    const [personaComList, setPersonaComList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();


    useEffect(() => {
        history.push({
            pathname: history.location.pathname,
            search: `?search=${searchTerm.txtSearch}`
        })
    }, [history, searchTerm])

    useEffect(() => {
        const searchPersonaCom = async () => {
            try {
                setIsLoading(true);
                const params = {
                    txtSearch: searchTerm.txtSearch
                }
                const dtResponse = await personaApi.searchPersonaCom(params);
                const dtPersonaCom = dtResponse?.data?.dtPersonaCom;
                if (dtPersonaCom) {
                    setPersonaComList(dtPersonaCom);
                } else {
                    setPersonaComList([]);
                }

            } catch (error) {
                alert(error);
            } finally {
                setIsLoading(false);
            }
        }

        searchPersonaCom();
        return;
    }, [searchTerm])
    return { searchTerm, setSearchTerm, personaComList, isLoading }
}