import styled from 'styled-components';

export const Wrapper = styled.div`
    /* height: 100%; */
    display: flex;
    flex-direction:column;

`;

export const Header = styled.div`
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items:center;
    padding: 5px 10px;
    background-color:#d3e8d8;
    font-size:var(--fontSMed);
    font-weight:bold;

    .btn {
        padding:2px 13px;
        background-color: var(--morienGreen);
        border: 1px solid var(--morienGreen);
        border-radius: 3px;
        color: var(--white);
        text-decoration: none;
        font-size:var(--fontNormal);
        font-weight:400;
    }
    
`;

export const DataList = styled.div`
    @media screen and (max-width: 768px){
        padding-bottom: 20px;
    }
`;