import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from './Auth';

export const PrivateRoute = ({ component: Component, ...rest }) => {

    const isLogin = Auth.isLogin();

    return (
        <Route {...rest} render={props => {
            return (
                isLogin ? <Component {...props} />
                    : <Redirect to='/login' />
            )
        }}
        />
    )
}

export const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    const isLogin = Auth.isLogin();

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            isLogin && restricted ?
                <Redirect to="/" />
                : <Component {...props} />
        )} />
    );
};
