import styled from 'styled-components';

export const Wrapper = styled.div`
    /* display: none; */
    max-width: 250px;
    height:100%;
    background-color:var(--lightGreen);

    @media screen and (max-width: 768px){
        width: 100vw;
        max-width: 100vw;
        height: fit-content;
        max-height: 100px;   
        position: fixed;
        bottom: 0;
        left: 0;
    }
    
`;

export const MorienLogo = styled.div`
    padding: 10px 30px 20px 10px;
    img {
        display:block;
        width: 200px;
    }
    @media screen and (max-width: 768px){
        display: none;
    }
`;

export const Menu = styled.div`
    ul {
        list-style-type: none;
        padding: 0;
        margin:0;
        @media screen and (max-width: 768px){
            display: flex;
            justify-content:space-between;
            padding: 0;
            font-size: 1.1rem;
        }
    }
    
    ul li {
        @media screen and (max-width: 768px){
            margin: 0 ;
            flex-grow: 1;
        }
        
    }

    ul li a {
        color: #007bff;
        text-decoration:none;
        padding: 13px 0;
        display:block;
        text-align: center;
        font-weight: 600;
        height: 100%;
       
        
    }
    .active{        
        background-color:#007bff;
        color: var(--white);
    }
`;
