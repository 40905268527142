import React, { useEffect } from "react";
import { useParams } from "react-router";
import Loading from "../../Loading";
import HeaderDetails from '../../../components/HeaderDetails'
import { Wrapper, Content, Info, MLabel } from './styles'
import { useClientActionDetails } from "./useClientActionDetails";

const ClientActionDetails = () => {
    const { action_id } = useParams();
    const { setClientActionID, objClientAction, isLoading } = useClientActionDetails();
    useEffect(() => {
        setClientActionID(action_id);
        return;
    }, [action_id, setClientActionID]);

    return (
        <>
            {!objClientAction ? null :
                <Wrapper>
                    <Loading isLoading={isLoading} />
                    <HeaderDetails mTitle={objClientAction.ActionTitle} />
                    <Content>
                        <Info>
                            <div>
                                <MLabel>お客様：</MLabel>
                                {objClientAction.ClientName || '―'}
                            </div>
                            <div>
                                <MLabel>客様表題：</MLabel>
                                {objClientAction.ClientTitle || '―'}
                            </div>
                            <div>
                                <MLabel>問合日：</MLabel>
                                {objClientAction.ActionDate ? objClientAction.ActionDate.slice(0, 10) : '－'}
                            </div>
                            <div>
                                <MLabel>支店：</MLabel>
                                {objClientAction.ChargePersonaName || '―'}
                            </div>
                            <div>
                                <MLabel>自社担当：</MLabel>
                                {objClientAction.StaffName || '―'}
                            </div>
                            <div>
                                <MLabel>代理：</MLabel>
                                {objClientAction.AltStaffName || '―'}
                            </div>
                            <div>
                                <MLabel>作成拠点：</MLabel>
                                {objClientAction.BaseName || '―'}
                            </div>
                            <div>
                                <MLabel>区分：</MLabel>
                                {objClientAction.ClientActionType || '―'}
                            </div>
                            <div>
                                <MLabel>流入経路：</MLabel>
                                {objClientAction.InflowMemo || '―'}
                            </div>
                            <div>
                                <MLabel>納入期日：</MLabel>
                                {objClientAction.DeliveryDate || '―'}
                            </div>
                            <div>
                                <MLabel>納入の住所：</MLabel>
                                〒{objClientAction.PostalCode || '―'}　{(objClientAction.Pref + objClientAction.City + objClientAction.AdrNumber + objClientAction.Building) || '―'}
                            </div>
                            <div>
                                <MLabel>TEL：</MLabel>
                                {objClientAction.TEL || '―'}
                            </div>
                            <div>
                                <MLabel>納品書の備考欄：</MLabel>
                                {objClientAction.ReportComment || '―'}
                            </div>
                            <div>
                                <MLabel>お問い合わせ内容・ご意見・ご質問：</MLabel>
                                {objClientAction.ActionMemo || '―'}
                            </div>
                            <div>
                                <MLabel>商品・色・仕上げに関して：</MLabel>
                                {objClientAction.ColorInfo || '―'}
                            </div>
                            <div>
                                <MLabel>ご担当者メモ：</MLabel>
                                {objClientAction.Personnel || '―'}
                            </div>
                            <div>
                                <MLabel>ペイント予定の場所：</MLabel>
                                {objClientAction.ToBePainted || '―'}
                            </div>
                            {/* <div>
                                <MLabel>住所：</MLabel>
                                〒{objOccation.PostalCode || '―'}　{objOccation.Address || '―'}
                            </div>
                            <div>
                                <MLabel>担当者：</MLabel>{objOccation.StaffName || '－'}
                            </div>
                            <div>
                                <MLabel>エリア：</MLabel>{objOccation.BaseName || '－'}
                            </div>
                            <div>
                                <MLabel>ステータス：</MLabel>{objOccation.StatusName || '－'}
                            </div>
                            <div>
                                <MLabel>次回アポ日：</MLabel>{objOccation.NextAppoinment ? objOccation.NextAppoinment.slice(0, 10) : '－'}
                            </div>
                            <div>
                                <MLabel>お客様：</MLabel>{objOccation.ClientName || '－'}
                            </div> */}

                            {/* <ClientActionList projectID={objProject.ProjectID} clientID={objProject.ClientID} staffID={objProject.StaffID} clientActionList={clientActionList} /> */}
                        </Info>
                    </Content>
                </Wrapper>
            }
        </>
    );
};

export default ClientActionDetails;
