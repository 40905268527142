import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 20px;
`;

export const Content = styled.div`
    /* width:100%; */
    flex-grow:1;
    display:flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-bottom: 30px;
    .mx-15{
        margin-left: 15px;
        margin-right: 15px;
    }
    input {
        font-size: var(--fontNormal);
        color: var(--black);
        border: 1px solid;
        border-radius: 3px;
        padding: 2px 5px;
    }

    select {
        font-size: var(--fontNormal);
        color: var(--black);
        border: 1px solid;
        border-radius: 3px;
    }
    textarea {
        font-size: var(--fontNormal);
        color: var(--black);
        border: 1px solid;
        border-radius: 3px;
    }
    .w-10{
        width: 10%
    }

    .w-20{
        width: 20%
    }

    .w-30{
        width: 30%
    }

    .w-40{
        width: 40%
    }

    .w-50{
        width: 50%
    }
    .w-60{
        width: 60%
    }
    .w-70{
        width: 70%
    }
    .w-80{
        width: 80%
    }
    .w-100{
        width: 100%
    }
    
    .btn-control {
        display: flex;
        justify-content: flex-end;
    }

    .bg-readOnly {
        background-color: #dddddd;
    }
    .btn-cancel{
        background-color: lightGrey;
        color: red;
        border: 1px solid lightGrey;
    }
`;

export const MBtnRegister = styled.button`
    margin-top: 5px;
    margin-left: 20px;
    padding:5px 20px;
    background-color: var(--morienGreen);
    border: 1px solid var(--morienGreen);
    border-radius: 3px;
    color: var(--white);
    text-decoration: none;
    font-size:var(--fontNormal);
    font-weight:400;
    :hover{
        background-color: green;
        cursor: pointer;
    }
   
`

export const MButton = styled.button`
    margin-left: 10px;
    padding:3px 10px;
    background-color: var(--morienGreen);
    border: 1px solid var(--morienGreen);
    border-radius: 3px;
    color: var(--white);
    text-decoration: none;
    font-size:var(--fontSmall);
    :hover{
        background-color: green;
        cursor: pointer;
    }
`

export const MLabel = styled.label`
    font-size: var(--fontSmall);
    padding: 0 5px;
    color: #6c757d;
`

export const Mark = styled.label`
    color: red;
`
export const ItemSelectWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px 5px;
    border-bottom: 2px solid #ececec;
    text-decoration: none;
    .row-title {
        font-weight: bold;
    }
`
export const ItemSelect = styled.div`
    font-size:0.9rem;
    width: 80%;   
`

export const ItemSelectRow = styled.div`
    padding-top:3px;
    font-size:0.9rem;
`
