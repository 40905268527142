import { useEffect, useRef, useState } from 'react';
import loginApi from '../../api/loginApi';
import Common from '../../Common'

const initLoginInfo = {
    userID: '',
    password: ''
}
export const useLogin = () => {
    const [loginInfo, setLoginInfo] = useState(initLoginInfo);
    const [isLoading, setIsLoading] = useState(false);
    const isFirstLoad = useRef(true);

    async function login(loginUser) {
        try {
            setIsLoading(true);
            /**Call Api */
            const dtResponse = await loginApi.login(loginUser);
            let aUserID = dtResponse?.data['A-UserID'] ?? "";
            let aToken = dtResponse?.data['A-Token'] ?? "";
            let aStaffID = dtResponse?.data['A-StaffID'] ?? "";
            let aStaffName = dtResponse?.data['A-StaffName'] ?? "";
            console.log(dtResponse);
            if (aUserID && aToken && aStaffID && aStaffName) {
                Common.localStorageSetToken(aUserID, aToken, aStaffID, aStaffName);
                window.location.replace(Common.URL_HOME);
            } else {
                //TODO: ERROR
                throw new Error('エラーが発生しました。');
            }
        } catch (err) {
            alert('ログインに失敗しました！');
            console.log(err);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (isFirstLoad.current) {
            isFirstLoad.current = false;
            return;
        }
        const loginUser = {
            userID: loginInfo.userID,
            password: loginInfo.password
        }
        login(loginUser);
    }, [loginInfo])

    return { setLoginInfo, isLoading };
}