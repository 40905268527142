import { useEffect, useState } from "react";
import clienActionApi from "../../../api/clientActionApi";


export const useClientActionDetails = () => {
    const [clientActionID, setClientActionID] = useState(null)
    const [objClientAction, setObjClientAction] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getClientActionDetails = async (clientActionID) => {
        try {
            setIsLoading(true);
            if (!clientActionID) {
                setObjClientAction(null);
                return;
            };
            const params = {
                clientActionID: clientActionID
            }
            const dtResponse = await clienActionApi.getClientActionDetails(params);
            const clientActionDetails = dtResponse?.data?.clientActionDetails;

            if (clientActionDetails) {
                setObjClientAction(clientActionDetails);
            } else {
                setObjClientAction(null);
            }
        } catch (error) {
            alert(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getClientActionDetails(clientActionID);
        return;
    }, [clientActionID])

    return { setClientActionID, objClientAction, isLoading }
}