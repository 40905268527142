import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import projectApi from "../../api/projectApi";



export const useProject = () => {
    const location = useLocation();
    const nameSearch = useMemo(() => {
        return (new URLSearchParams(location.search)).get("search") || '';
    }, [location.search]);

    const initSearchTerm = {
        mode: 'project',
        txtSearch: nameSearch
    }

    const [searchTerm, setSearchTerm] = useState(initSearchTerm);
    const [projectList, setProjectList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const searchProject = async (searchTerm) => {
        try {
            setIsLoading(true);
            const params = {
                txtSearch: searchTerm.txtSearch
            }
            const dtResponse = await projectApi.searchProject(params);
            const dtProject = dtResponse?.data?.dtProject;
            if (dtProject) {
                setProjectList(dtProject);
            } else {
                setProjectList([]);
            }

        } catch (error) {
            alert(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        history.push({
            pathname: history.location.pathname,
            search: `?search=${searchTerm.txtSearch}`
        })
    }, [history, searchTerm])

    useEffect(() => {
        searchProject(searchTerm);
        return;
    }, [searchTerm])
    return { searchTerm, setSearchTerm, projectList, isLoading }
}