import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './Router';
import Login from './features/Login';

import { GlobalStyle } from './GlobalStyles'
import { Wrapper } from './App.styles';
import Project from './features/Project';
import ProjectDetails from './features/Project/Details';
import ClientActionDetails from './features/ClientAction/Details';
import ClientActionAddNew from './features/ClientAction/AddNew';
import ProjectAddNew from './features/Project/AddNew';
import PersonaCom from './features/PersonaCom';
import NavMenu from './components/NavMenu';
import Common from './Common';
import PersonaComDetails from './features/PersonaCom/Details';


function App() {

    return (
        <Wrapper>
            <Router basename={Common.BASE_NAME}>
                <NavMenu />
                <Switch>
                    {/* ANCHOR: ログイン */}
                    <PublicRoute
                        restricted={true}
                        path={['/login']} exact
                        component={Login}
                    />

                    {/* ANCHOR: 物件 */}
                    <PrivateRoute
                        path={['/', '/project']} exact
                        component={Project}
                    />

                    <PrivateRoute
                        path={['/project/new']} exact
                        component={ProjectAddNew}
                    />

                    <PrivateRoute
                        path={['/project/:project_id']} exact
                        component={ProjectDetails}
                    />

                    {/* ANCHOR: 問合せ */}
                    <PrivateRoute
                        path={['/project/client_action/add_new', '/com/client_action/add_new']} exact
                        component={ClientActionAddNew}
                    />
                    <PrivateRoute
                        path={['/project/client_action/:action_id', '/com/client_action/:action_id']} exact
                        component={ClientActionDetails}
                    />

                    {/* ANCHOR: 法人 */}
                    <PrivateRoute
                        path={['/com']} exact
                        component={PersonaCom}
                    />
                    <PrivateRoute
                        path={['/com/:persona_id']} exact
                        component={PersonaComDetails}
                    />
                </Switch>
                <GlobalStyle />
            </Router>
        </Wrapper>
    );
}

export default App;
