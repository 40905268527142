import { useEffect, useState } from "react";
import projectApi from "../../../api/projectApi";


export const useProjectDetails = () => {
    const [projectID, setProjectID] = useState(null)
    const [objProject, setObjProject] = useState(null);
    const [clientActionList, setClientActionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getProjectDetails = async (projectID) => {
        try {
            setIsLoading(true);
            if (!projectID) {
                setObjProject(null);
                return;
            };
            const params = {
                projectID: projectID
            }
            const dtResponse = await projectApi.getProjectDetails(params);
            const projectDetails = dtResponse?.data?.projectDetails;
            const dtClientAction = dtResponse?.data?.dtClientAction;
            if (projectDetails) {
                setObjProject(projectDetails);
                setClientActionList(dtClientAction);
            } else {
                setObjProject(null);
            }
        } catch (error) {
            alert(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getProjectDetails(projectID);
        return;
    }, [projectID])

    return { setProjectID, objProject, clientActionList, isLoading }
}