import React, { useEffect } from "react";
import SearchBox from "../../components/SearchBox";
import { Wrapper, Content } from './styles'
import Loading from '../Loading';
import { useProject } from './useProject'
import ProjectItem from "./components/ProjectItem";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

const Project = () => {
    const { searchTerm, setSearchTerm, projectList, isLoading } = useProject();
    const refTop = React.createRef();

    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [projectList, refTop])

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} textHolder="物件名" />
            <Content ref={refTop}>
                {projectList.length < 1 ? <h5 style={{ textAlign: 'center' }}>データなし</h5> :
                    projectList.map((objProject) => (
                        <ProjectItem key={objProject.ProjectID} objProject={objProject} />
                    ))
                }
            </Content>
            <Link to={{
                pathname: `/project/new`,
                state: {}
            }} className='btn-add'><FontAwesomeIcon icon={faCirclePlus} /></Link>
        </Wrapper>
    );
};

export default Project;
