import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position:fixed;
    top:0;
    bottom: 0;
    background-color: var(--white);
    z-index: 99;
    
`;
export const Spin = styled.div`
    border: 5px solid #1f1f1f;
    border-top: 5px solid var(--morienGreen);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: 20vh auto;
    @keyframes spin {
        0% {
        transform: rotate(0deg);
        }
        100% {
        transform: rotate(360deg);
        }
    }
`;