import React from "react";
import HeaderDetails from "../../../components/HeaderDetails";
import Modal from "../../../components/Modal";
import Loading from "../../Loading";
import { FormRegister, ItemSelectWrapper, Mark, MBtnRegister, MButton, MLabel, Wrapper } from "./styles";
import { useClientSearch } from "./useClientSearch";
import { useProjectAddNew } from "./useProjectAddNew";



const ProjectAddNew = () => {

    const { clientList, isLoadingClient, searchTerm, setSearchTerm, activeModal, setActiveModal } = useClientSearch();
    const { projectInfo, setProjectInfo, setIsSave, isLoading } = useProjectAddNew();

    const validateData = () => {
        let msg = '';

        if (projectInfo.projectName === '') {
            msg = '物件名を入力して下さい。';
        }

        if (projectInfo.staffID === '') {
            msg = '担当者を設定して下さい。';
        }

        if (projectInfo.clientName === '') {
            msg = 'お客様を選択して下さい。';
        }

        if (projectInfo.dateFrom === '' || projectInfo.dateUntil === '') {
            msg = '工期を入力して下さい。';
        }

        if (projectInfo.dateFrom > projectInfo.dateUntil) {
            msg = '終了日を開始日よりも後に設定して下さい。';
        }

        if (msg !== '') {
            alert(msg);
            return false;
        }
        return true;
    }

    const handleRegister = (ev) => {
        ev.preventDefault();

        if (validateData()) {
            setIsSave(true);
        }
        console.log("Register");
    }



    return (
        <Wrapper>
            <Loading isLoading={isLoadingClient || isLoading} />
            <HeaderDetails mTitle="物件登録" />
            <FormRegister onSubmit={handleRegister}>
                <div >
                    <MLabel>物件名<Mark>*</Mark>：</MLabel>
                    <div className='mx-15'>
                        <input type="text"
                            className='w-100'
                            value={projectInfo.projectName || ''}
                            maxLength={50}
                            onChange={(e) => { setProjectInfo({ ...projectInfo, projectName: e.target.value }) }}
                        />
                    </div>
                </div>
                <div >
                    <MLabel>フリガナ：</MLabel>
                    <div className='mx-15'>
                        <input type="text"
                            className='w-100'
                            value={projectInfo.projectKana || ''}
                            maxLength={50}
                            onChange={(e) => { setProjectInfo({ ...projectInfo, projectKana: e.target.value }) }}
                        />
                    </div>
                </div>
                <div >
                    <MLabel>担当者：</MLabel>
                    <div className='mx-15'>
                        <input type="text"
                            className='w-50 bg-readOnly'
                            value={projectInfo.staffName || ''}
                            readOnly
                        />
                    </div>
                </div>
                <div>
                    <MLabel>お客様<Mark>*</Mark>：</MLabel>
                    <div className='mx-15'>
                        <input type="text"
                            className='w-70 bg-readOnly'
                            value={projectInfo.clientName || ''}
                            readOnly
                        />
                        <MButton type="button" onClick={() => setActiveModal(true)}>選択</MButton>
                    </div>
                </div>
                <div>
                    <MLabel>工期<Mark>*</Mark>：</MLabel>
                    <div className='mx-15 content-horizontal'>
                        <input
                            type='date'
                            min='2010-01-01'
                            max='2100-12-31'
                            value={projectInfo.dateFrom}
                            onChange={(e) => { setProjectInfo({ ...projectInfo, dateFrom: e.target.value }) }}
                        />
                        <label style={{ marginLeft: "8px", marginRight: "8px" }}>～</label>
                        <input type='date'
                            min='2010-01-01'
                            max='2100-12-31'
                            value={projectInfo.dateUntil}
                            onChange={(e) => { setProjectInfo({ ...projectInfo, dateUntil: e.target.value }) }}
                        />
                    </div>
                </div>
                <div className="btn-control mx-15">
                    <MBtnRegister type="submit">保存</MBtnRegister>
                </div>
            </FormRegister>

            <Modal
                active={activeModal}
                hideModal={() => setActiveModal(false)}
                title="お客様を選択してください。"
                footer={<MButton onClick={() => setActiveModal(false)} >閉じる</MButton>}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                textHolder='お客様名'
            >

                {/* <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} textHolder="スタッフ名" /> */}
                {clientList.length < 1 ? <h5 style={{ textAlign: 'center' }}>データなし</h5> :
                    clientList.map((objClient) => (
                        <ItemSelectWrapper key={objClient.ClientID} >
                            <label className="wTitle">{objClient.ClientName}</label>
                            <MButton onClick={() => {
                                setProjectInfo({ ...projectInfo, clientID: objClient.ClientID, clientName: objClient.ClientName });
                                setActiveModal(false);
                            }}
                            >選択</MButton>
                        </ItemSelectWrapper>
                    ))
                }
            </Modal>

        </Wrapper>
    )
}

export default ProjectAddNew;