import React from 'react';
import { Wrapper, Spin } from './styles';
const Loading = ({ isLoading }) => {
    return (
        <>
            {isLoading ? (
                <Wrapper>
                    <Spin />
                </Wrapper>
            ) : ''}
        </>
    )
}

export default Loading
