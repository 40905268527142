import axios from 'axios';
import Common from '../Common';

const axiosClient = axios.create({
    //baseURL: 'http://192.168.0.81:8082/cw-astage/api',
    // baseURL: 'http://35.76.3.63/cw-astage/api',  //Old
    // baseURL: 'http://3.113.166.121/cw-astage/api',     //New
    baseURL: Common.BASE_URL_API,     //New
    headers: {
        'Content-Type': 'application/json',
    }
})

// Interceptors
// Add a request interceptor
axiosClient.interceptors.request.use(
    function (config) {
        const { aUserID, aToken, aStaffID } = Common.localStorageGetToken();
        config.headers['A-UserID'] = aUserID;
        config.headers['A-Token'] = aToken;
        config.headers['A-StaffID'] = aStaffID;
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data;
    },
    function (error) {
        //TODO: Catch Error
        console.log('エラー：' + error.message);
        console.log(error.response);
        if (error.response?.status === 401) {
            Common.localStorageRemoveToken();
            window.location.replace(Common.URL_LOGIN);
        }
        return Promise.reject(error);
    }
);

export default axiosClient
