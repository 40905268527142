import React, { useEffect } from "react";
import SearchBox from "../../components/SearchBox";
import { Wrapper, Content } from './styles'
import Loading from '../Loading';
import { usePersonaCom } from './usePersonaCom'
import PersonaComItem from "./components/PersonaComItem";

const PersonaCom = () => {
    const { searchTerm, setSearchTerm, personaComList, isLoading } = usePersonaCom();
    const refTop = React.createRef();

    useEffect(() => {
        refTop.current.scrollTo(0, 0);
        return;
    }, [personaComList, refTop])

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} textHolder="法人名" />
            <Content ref={refTop}>
                {personaComList.length < 1 ? <h5 style={{ textAlign: 'center' }}>データなし</h5> :
                    personaComList.map((objPersona) => (
                        <PersonaComItem key={objPersona.PersonaID} item={objPersona} />
                    ))
                }
            </Content>

        </Wrapper>
    );
};

export default PersonaCom;
