import axiosClient from "./axiosClient";

const clientActionApi = {

    getClientActionDetails(params) {
        const url = '/client_action/details';
        return axiosClient.get(url, { params: params });
    },
    getClientActionType(params) {
        const url = '/client_action/type';
        return axiosClient.get(url, { params: params });
    },
    addNewClientAction(params) {
        const url = '/client_action/add_new';
        return axiosClient.post(url, params);
    },
}

export default clientActionApi;
