import React from "react";
import Loading from "../../Loading";
import HeaderDetails from '../../../components/HeaderDetails'
import { Wrapper, Content, MBtnRegister, MLabel, Mark, ItemSelectWrapper, MButton, ItemSelectRow, ItemSelect } from './styles'
import { useClientActionAddNew } from "./useClientActionAddNew";
// import JapanPostalCode from "japan-postal-code";
import Modal from "../../../components/Modal";
import { useHistory } from "react-router-dom";


const ClientActionAddNew = () => {
    const history = useHistory();
    const { clientAction, setClientAction, clientActionTypeList, projectID
        , isLoading, setIsSave
        , clientList, searchTerm, setSearchTerm, activeModal, setActiveModal
        , projectList, searchTermProject, setSearchTermProject, activeModalProject, setActiveModalProject
    } = useClientActionAddNew();
    function checkValidate() {
        console.log(clientAction);
        if (!clientAction.staffID) {
            alert("担当者を指定してください。");
            return false;
        }

        if (!clientAction.clientID) {
            alert("お客様を指定してください。");
            return false;
        }

        if (clientAction.projectID < 2) {
            alert("物件を指定してください。");
            return false;
        }

        if (!clientAction.actionTitle) {
            alert("件名を指定してください。");
            return false;
        }
        if (!clientAction.actionDate) {
            alert("日付を指定してください。");
            return false;
        }
        if (!clientAction.clientActionTypeID) {
            alert("区分を指定してください。");
            return false;
        }
        return true;
    }
    function handleSave_Click() {
        if (checkValidate()) {
            setIsSave(true);
        }
    }

    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderDetails mTitle="問合せ登録" />
            {clientAction &&
                <>
                    <Content>
                        <div>
                            <MLabel>物件<Mark>*</Mark>：</MLabel>
                            <div className='mx-15'>
                                <input type="text"
                                    className='w-80 bg-readOnly'
                                    value={clientAction.projectName || ''}
                                    readOnly
                                />
                                {projectID > 1 ? <></> : <MButton type="button" onClick={() => setActiveModalProject(true)}>選択</MButton>}
                            </div>
                        </div>
                        <div>
                            <MLabel>お客様<Mark>*</Mark>：</MLabel>
                            <div className='mx-15'>
                                <input type="text"
                                    className='w-80 bg-readOnly'
                                    value={clientAction.clientName || ''}
                                    readOnly
                                />
                                <MButton type="button" onClick={() => setActiveModal(true)}>選択</MButton>
                            </div>
                        </div>
                        <div >
                            <MLabel>自社担当：</MLabel>
                            <div className='mx-15'>
                                <input type="text"
                                    className='w-50 bg-readOnly'
                                    value={clientAction.staffName || ''}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div >
                            <MLabel>件名<Mark>*</Mark>：</MLabel>
                            <div className='mx-15'>
                                <input type="text"
                                    className='w-100'
                                    value={clientAction.actionTitle || ''}
                                    maxLength={50}
                                    onChange={(e) => { setClientAction({ ...clientAction, actionTitle: e.target.value }) }}
                                />
                            </div>
                        </div>
                        <div>
                            <MLabel>日付<Mark>*</Mark>：</MLabel>
                            <div className='mx-15'>
                                <input type='date'
                                    min='2010-01-01'
                                    max='2100-12-31'
                                    value={clientAction.actionDate}
                                    onChange={(e) => { setClientAction({ ...clientAction, actionDate: e.target.value }) }}
                                />
                            </div>
                        </div>
                        {clientActionTypeList.length > 0 ? (
                            <div>
                                <MLabel>区分<Mark>*</Mark>：</MLabel>
                                <div className='mx-15'>
                                    <select value={clientAction.clientActionTypeID}
                                        onChange={(e) => { setClientAction({ ...clientAction, clientActionTypeID: e.target.value }) }}>
                                        {clientActionTypeList.map((iType, index) => {
                                            return (<option key={iType.ClientActionTypeID} value={iType.ClientActionTypeID}>{iType.ClientActionType}</option>);
                                        })}
                                    </select>
                                </div>
                            </div>
                        ) : null}

                        <div>
                            <MLabel>お問い合わせ内容・ご意見・ご質問：</MLabel>
                            <div className='mx-15'>
                                <textarea
                                    type='text'
                                    maxLength={300}
                                    style={{ width: '100%', minHeight: '120px' }}
                                    value={clientAction.actionMemo}
                                    onChange={(e) => { setClientAction({ ...clientAction, actionMemo: e.target.value }) }}
                                />
                            </div>
                        </div>
                        <div className="btn-control mx-15">
                            <MBtnRegister className="btn-cancel" onClick={() => { history.goBack(); }}>キャンセル</MBtnRegister>
                            <MBtnRegister onClick={handleSave_Click}>保存</MBtnRegister>
                        </div>
                    </Content>
                    <Modal
                        active={activeModal}
                        hideModal={() => setActiveModal(false)}
                        title="お客様を選択してください。"
                        footer={<MButton style={{ color: 'red', background: 'lightGray', borderColor: 'lightGrey' }} onClick={() => setActiveModal(false)} >閉じる</MButton>}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        textHolder='お客様名'
                    >

                        {/* <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} textHolder="スタッフ名" /> */}
                        {clientList.length < 1 ? <h5 style={{ textAlign: 'center' }}>データなし</h5> :
                            clientList.map((objClient) => (
                                <ItemSelectWrapper key={objClient.ClientID} >
                                    <ItemSelect>
                                        <ItemSelectRow className="row-title">{objClient.ClientName}</ItemSelectRow>
                                    </ItemSelect>
                                    <div>
                                        <MButton onClick={() => { setClientAction({ ...clientAction, clientID: objClient.ClientID, clientName: objClient.ClientName }); setActiveModal(false); }}>選択</MButton>
                                    </div>
                                </ItemSelectWrapper>
                            ))
                        }
                    </Modal>
                    <Modal
                        active={activeModalProject}
                        hideModal={() => setActiveModalProject(false)}
                        title="物件を選択してください。"
                        footer={<MButton style={{ color: 'red', background: 'lightGrey', borderColor: 'lightGrey' }} onClick={() => setActiveModalProject(false)} >閉じる</MButton>}
                        searchTerm={searchTermProject}
                        setSearchTerm={setSearchTermProject}
                        textHolder='物件名'
                    >

                        {/* <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} textHolder="スタッフ名" /> */}
                        {projectList.length < 1 ? <h5 style={{ textAlign: 'center' }}>データなし</h5> :
                            projectList.map((objProject) => (
                                <ItemSelectWrapper key={objProject.ProjectID} >
                                    <ItemSelect>
                                        <ItemSelectRow className="row-title">{objProject.ProjectName}</ItemSelectRow>
                                        <ItemSelectRow >
                                            <MLabel>実行日：</MLabel>{objProject.DateFrom}～{objProject.DateUntil}
                                        </ItemSelectRow>

                                    </ItemSelect>
                                    <div>
                                        <MButton onClick={() => { setClientAction({ ...clientAction, projectID: objProject.ProjectID, projectName: objProject.ProjectName }); setActiveModalProject(false); }}>選択</MButton>
                                    </div>

                                </ItemSelectWrapper>
                            ))
                        }
                    </Modal>
                </>
            }

        </Wrapper >
    );
};

export default ClientActionAddNew;
