import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import clienActionApi from "../../../api/clientActionApi";
import clientApi from "../../../api/clientApi";
import projectApi from "../../../api/projectApi";
import Common from "../../../Common";

const dNow = new Date();
const staff = Common.localStorageGetStaff();
const initClientAction = {
    clientID: 1,    //必須   
    clientName: "",
    clientActionTypeID: 1,  //必須
    projectID: 1,  //固定
    projectName: "未入力",
    actionDate: `${dNow.getFullYear()}-${(dNow.getMonth() + 1).toString().padStart(2, '0')}-${dNow.getDate().toString().padStart(2, '0')}`,
    actionTitle: "",
    actionMemo: "",
    staffID: staff.staffID || '',    //固定　自社担当 
    staffName: staff.staffName || '',
}

export const useClientActionAddNew = () => {
    const location = useLocation();
    const { projectID, projectName, clientID, clientName, persinaID } = location.state;
    const [clientAction, setClientAction] = useState({ ...initClientAction, projectID, projectName, clientID, clientName });
    const [clientActionTypeList, setClientActionTypeList] = useState([]);
    const [isSave, setIsSave] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeModal, setActiveModal] = useState(false);
    // 物件
    const [projectList, setProjectList] = useState([]);
    const [searchTermProject, setSearchTermProject] = useState('');
    const [activeModalProject, setActiveModalProject] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            try {
                setIsLoading(true);
                //区分
                const dtResponse = await clienActionApi.getClientActionType(null);
                const dtClientActionType = dtResponse?.data?.dtClientActionType || [];
                if (dtClientActionType) setClientActionTypeList(dtClientActionType);

            } catch (error) {
                alert(error);
            } finally {
                setIsLoading(false);
            }
        }
        loadData();
    }, []);

    useEffect(() => {
        const searchClient = async () => {
            try {
                setIsLoading(true);
                const params = {
                    txtSearch: searchTerm.txtSearch
                }
                const dtResClient = await clientApi.getAllClient(params);
                const dtClient = dtResClient?.data?.dtClient || [];
                if (dtClient) setClientList(dtClient);

            } catch (error) {
                alert(error);
            } finally {
                setIsLoading(false);
            }
        }

        searchClient();
    }, [searchTerm]);

    // 物件選択
    useEffect(() => {
        const searchProjectClient = async () => {
            try {
                setIsLoading(true);
                const params = {
                    clientID: clientID,
                    txtSearch: searchTermProject.txtSearch
                }
                const dtResProject = await projectApi.searchProjectClient(params);
                const dtProject = dtResProject?.data?.dtProject || [];
                setProjectList(dtProject);

            } catch (error) {
                alert(error);
            } finally {
                setIsLoading(false);
            }
        }

        searchProjectClient();
    }, [searchTermProject, clientID]);

    useEffect(() => {
        const saveData = async () => {
            try {
                setIsLoading(true);
                const params = {
                    actionTitle: clientAction.actionTitle,
                    clientID: clientAction.clientID,
                    projectID: clientAction.projectID,
                    clientActionTypeID: clientAction.clientActionTypeID,
                    staffID: clientAction.staffID,
                    actionDate: clientAction.actionDate,
                    actionMemo: clientAction.actionMemo
                }
                const dtResponse = await clienActionApi.addNewClientAction(params);
                alert(dtResponse.message);
                if (projectID > 1) {
                    window.location.replace(Common.URL_HOME + '/' + projectID);
                } else {
                    window.location.replace(Common.BASE_NAME + '/com/' + persinaID);
                }

            } catch (error) {
                alert(error);
            } finally {
                setIsLoading(false);
                setIsSave(false);
            }
        }

        if (isSave) {
            saveData();
        }
    }, [isSave, clientAction, projectID]);


    return {
        clientAction, setClientAction, clientActionTypeList, projectID
        , isLoading, setIsSave
        , clientList, searchTerm, setSearchTerm, activeModal, setActiveModal
        , projectList, searchTermProject, setSearchTermProject, activeModalProject, setActiveModalProject
    }
}