import React from "react";
import Loading from "../../Loading";
import HeaderDetails from '../../../components/HeaderDetails'
import { Wrapper, Content, Info, MLabel, Line } from './styles'
import ClientActionList from "../../../components/ClientAction/List";
import { usePersonaComDetails } from "./usePersonaComDetails";

const PersonaComDetails = () => {

    const { objPersonaCom, clientActionList, isLoading } = usePersonaComDetails();

    return (
        <>
            {!objPersonaCom ? null :
                <Wrapper>
                    <Loading isLoading={isLoading} />
                    <HeaderDetails mTitle={objPersonaCom.PersonaNameExtra} />
                    <Content>
                        <Info>
                            <Line>
                                <MLabel>住所：</MLabel>
                                〒{objPersonaCom.PostalCode || '―'}　{objPersonaCom.Adr || '―'}
                            </Line>
                            <Line>
                                <MLabel>TEL：</MLabel>
                                {objPersonaCom.TEL || '―'}
                                &emsp;&emsp;
                                <MLabel>Fax：</MLabel>
                                {objPersonaCom.Fax || '―'}
                            </Line>

                            <ClientActionList parrentUrl={'com'} projectID={'1'} clientID={objPersonaCom?.ClientID || ''} clientName={objPersonaCom?.ClientName || ''} clientActionList={clientActionList} persinaID={objPersonaCom.PersonaID} />
                        </Info>
                    </Content>
                </Wrapper>
            }
        </>
    );
};

export default PersonaComDetails;
