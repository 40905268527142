import axiosClient from "./axiosClient";

const personaApi = {

    getBranchOfClient(params) {
        const url = '/persona/branch_of_client';
        return axiosClient.get(url, { params: params });
    },
    searchPersonaCom(params) {
        const url = '/persona/com';
        return axiosClient.get(url, { params: params });
    },
    getPersonaComDetails(params) {
        const url = '/persona/com/details';
        return axiosClient.get(url, { params: params });
    },
}

export default personaApi;
